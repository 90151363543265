import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TRecommendationListItem } from "../../lib/types";
import GuidelineLink from "../common/GuidelineLink";
import TypeHeader from "../common/TypeHeader";
import RecommendationCertainty from "./RecommendationCertainty";
import RecommendationSnippet from "./RecommendationSnippet";
import RecommendationStrength from "./RecommendationStrength";

interface IRecommendationItem {
  recommendation: TRecommendationListItem,
  displaySource: boolean
}

const RecommendationItem = React.memo(({ recommendation, displaySource = true }: IRecommendationItem) => {
  const {
    "@id": id,
    guideline,
    recommendation: text,
    gradeCertaintyOfEvidence,
    gradeCertaintyOfEvidenceLabel,
    recommendationDirection,
    recommendationFormality,
    gradeStrength,
    guidelineSource,
    transformed,
  } = recommendation;

  const { t } = useTranslation();
  return (
    <div className="recommendation-item bg-white border border-gray-1100 mb-3 last:mb-0 hover:shadow text-sm">
      <Link className="text-gray-800 block p-4" to={`/recommendation/${id}`}>
        <div className="flex flex-row">
          <TypeHeader
            className="flex-grow text-base"
            recommendationFormality={recommendationFormality}
            size="small"
            type="recommendation"
          />
          <span className="text-red-200 underline text-xs roboto">{t(`labels.see_more`)}</span>
        </div>
        <div className="block mt-2 roboto text-gray-1300">
          {guideline && <GuidelineLink guideline={guideline} />}
          <RecommendationSnippet isGrid={false} text={text} />
          {displaySource && guidelineSource && (
            <div className="font-semibold my-1 text-xs text-red-200">{guidelineSource}</div>
          )}
        </div>
        <div className="flex text-2xs">
          <div className="mt-1 flex-grow">
            {gradeCertaintyOfEvidence && (
              <RecommendationCertainty
                noBox
                signSize="small"
                transformed={transformed}
                gradeCertaintyOfEvidenceLabel={gradeCertaintyOfEvidenceLabel}
                gradeCertaintyOfEvidence={gradeCertaintyOfEvidence}
              />
            )}
          </div>
          <div className="mt-1">
            {gradeStrength && (
              <>
                <div className="mt-1 font-semibold">
                  {t("recommendation.recommendation_strength")}
                </div>
                <RecommendationStrength
                  noBox
                  gradeStrength={gradeStrength}
                  recommendationDirection={recommendationDirection}
                />
              </>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
});

export default RecommendationItem;
