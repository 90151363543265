import React, { useMemo } from "react";
import _ from "lodash";
import RecommendationItem from "./RecommendationItem";
import { TRecommendationListItem } from "../../lib/types";

interface IRecommendationsList {
  recommendations: TRecommendationListItem[];
  displaySource?: boolean;
}

const RECOMMENDATIONS_ORDER = [
  "2908b8f8-6d05-44f9-ac87-4362ace455cb",
  "1892e9ae-e858-4214-b711-a5d830b4dd59",
  "3c665ab5-96a2-4519-8617-3b047e30efeb",
  "7a35f9d5-c9cb-4848-8cd9-e665efe57987",
  "00f30e91-1387-4c33-a5a1-ac539d5a4b36",
  "c76fdf81-c99b-46f3-90ba-8f7c24499359",
  "11b5064e-f9c7-4e07-9ef4-ac642aa36f14",
  "489b7073-d487-4fde-9662-45a11433880d",
  "eb6b2a5b-fba2-4bcf-bbcf-49f08c655b88",
  "99cbc3b3-afb7-4ba2-be64-8e3e22a157f6",
  "0b856b0f-003e-46dd-a778-dbbf78764aaf",
  "a78c9a38-9ec3-43eb-acd0-23bf87ce578c",
  "d8605338-9ecf-43f2-95bf-215bf44dde08",
  "396eb58f-63b2-47ba-b6e7-dc9cc23ef3e0",
  "6b79f8de-b902-44b0-b55d-4598bf926b81",
  "2724a16b-1ec6-449a-9f01-2abb8176d2d5",
  "24c96691-691b-4dca-aeef-49a45791d319",
  "a5a56fe7-3266-4854-8c91-036e572cf71c",
  "42783958-5d33-4366-9a5e-25a48571c409",
  "714914f3-a201-4e35-81b1-19320e5c8310",
  "67045d71-e200-46e1-a29b-5338fc13bce4",
  "196d408c-3578-404a-82c9-bf2b74ddbb7c",
  "62b439c2-84b5-4d73-abfc-3f940920693a",
  "8575dde6-8502-403e-8fff-d0a49e23c298",
  "c958ce3d-a6f1-42ca-9dba-dbcadd37079f",
  "a9ec9c0e-319a-40a9-b34f-0a7ffbea6e0a",
  "a064491e-6b68-4e6d-b666-139c6a6bb50d",
  "0ae7603e-610b-44b1-b7b4-fd64585438dd",
  "cf2e5e87-c465-4154-9944-94cabbb1ded1",
  "cdde544f-e773-4fd9-bab5-16e8ee4f8cb9",
  "65bf69e8-e52f-4f37-9e0f-2aa61d1462d0",
  "8d2649a0-c024-47d5-b79e-48377571dac0",
  "3e20c674-0162-42d5-9cc9-31fdc3b57247",
  "f9246f25-3f0c-4533-b266-1653a8f737f6",
  "f0911af2-6a46-4e47-9153-e5f97e9f1a0a",
  "f40c1639-ddc0-4cc9-a951-267c1dae348a",
  "e99d30de-0cff-43c5-bc23-899abc4460cb",
];

const RecommendationsList = ({ recommendations, displaySource = true }: IRecommendationsList) => {
  const recommendationsKeyed = useMemo(() => _.keyBy(recommendations, "@id"), [recommendations]);

  return (
    <ul className="recommendation-list ml-1 md:ml-1 mt-6 mr-1 md:mr-2">
      <li>
        <div className="bg-transparent pb-1">
          <div className="mb-1">
            {_.map(RECOMMENDATIONS_ORDER, (recommendationId) => {
              return recommendationsKeyed[recommendationId] ? (
                <RecommendationItem
                  key={recommendationId}
                  recommendation={recommendationsKeyed[recommendationId]}
                  displaySource={displaySource}
                />
              ) : null;
            })}
          </div>
        </div>
      </li>
    </ul>
  );
};

export default RecommendationsList;
